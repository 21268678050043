<template>
  <div>
    <el-dialog
      :title="title + '房间设备'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="酒店" prop="hotelId">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.hotelId"
                  placeholder="请选择酒店"
                  :disabled="hotel"
                >
                  <el-option
                    v-for="item in hotelList"
                    :key="item.id"
                    :label="item.hotelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房型" prop="typeName">
                <el-input
                  :disabled="hotel"
                  v-model="typeName"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房间" prop="roomId" style="width: 100%">
                <el-input
                  placeholder="请输入房间"
                  v-model="roomNo"
                  :disabled="hotel"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备号" prop="deviceNo" style="width: 100%">
                <el-input
                  placeholder="请输入设备号"
                  v-model="formInline.deviceNo"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备别名" prop="deviceNameId">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.deviceNameId"
                  placeholder="请选择设备别名"
                >
                  <el-option
                    v-for="item in DeviceList"
                    :key="item.id"
                    :label="item.deviceOtherName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备类型" prop="deviceType">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.deviceType"
                  placeholder="请选择设备类型"
                >
                  <el-option
                    v-for="item in deviceList"
                    :key="item.code"
                    :label="item.typeName"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <!-- <div class="banben"> -->

          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="房间区域" prop="roomArea">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="formInline.roomArea"
                  placeholder="请选择房间区域"
                >
                  <el-option
                    v-for="item in roomAreaList"
                    :key="item.id"
                    :label="item.roomArea"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="位号" prop="placeNo" style="width: 100%">
                <el-input
                  placeholder="请输入位号"
                  v-model="formInline.placeNo"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%" v-if="title == '创建'">
              <el-form-item
                label="控制设备数量"
                prop="controlNum"
                style="width: 100%"
              >
                <el-input
                  placeholder="请输入控制设备数量"
                  v-model="formInline.controlNum"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%" v-if="title == '创建'">
              <el-form-item
                label="设备类型编号"
                prop="deviceTypeCode"
                style="width: 100%"
              >
                <el-input
                  placeholder="请输入设备类型编号"
                  v-model="formInline.deviceTypeCode"
                  style="width: 100%"
                  maxlength="10"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="小程序可见" prop="display">
                <el-radio-group v-model="formInline.display">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="bandanItem" style="width: 45%">
              <el-form-item label="设备类型" prop="display">
                <el-radio-group v-model="formInline.ioType">
                  <el-radio :label="0">输入</el-radio>
                  <el-radio :label="1">输出</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>

          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      typeName: null,
      roomNo: null,
      //表单
      formInline: {
        display: 0,
      },
      hotelList: [],
      roomStatusList: [],
      roomAreaList: [],
      deviceList: [],
      DeviceList: [],
      //表单验证
      rules: {
        deviceNo: [
          {
            required: true,
            message: "请输入设备号",
            trigger: "blur",
          },
        ],
        deviceNameId: [
          {
            required: true,
            message: "请选择设备别名",
            trigger: "blur",
          },
        ],
        deviceType: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: "blur",
          },
        ],
        roomArea: [
          {
            required: true,
            message: "请选择房间区域",
            trigger: "blur",
          },
        ],
        placeNo: [
          {
            required: true,
            message: "请输入位号",
            trigger: "blur",
          },
        ],
        controlNum: [
          {
            required: true,
            message: "请输入控制设备数量",
            trigger: "blur",
          },
        ],
        deviceTypeCode: [
          {
            required: true,
            message: "请输入设备类型编号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, typeName, roomNo, val, index) {
      this.formInline = {};
      this.typeName = typeName;
      this.roomNo = roomNo;
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = Number(hotelInfor.hotelId);
      this.getHotelList();
      this.getRoomStatusList();
      this.getRoomAreaList();
      this.getDeviceList();
      this.getdeviceList();
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
      if (item == "编辑") {
        this.bedisabled = true;
        this.formInline = JSON.parse(JSON.stringify(val));
        this.formInline.ioType = Number(this.formInline.ioType);
        this.formInline.display = Number(this.formInline.display);
        // this.formInline.roomStatus = Number(this.formInline.roomStatus);

        console.log(this.formInline);
      }
    },
    getRoomAreaList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        num: 10000,
        offset: 1,
      };
      postRequest("selectAllHotelRoomArea", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomAreaList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getdeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceDefinition", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.DeviceList = res.data;
          console.log(345, this.deviceList);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getDeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysDeviceType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            this.formInline.roomId = this.$route.query.id;
            // this.formInline.roomId = this.$route.query.id;
            this.deviceList.forEach((item) => {
              if (item.code == this.formInline.deviceType) {
                this.formInline.deviceTypeName = item.typeName;
              }
            });
            postRequest("/insertHotelRoomDevice", this.formInline).then(
              (res) => {
                if (res.status == 200) {
                  this.$message({
            duration: 5000,
                    message: "创建成功!",
                    type: "success",
                  });
                  this.hide();
                  this.$refs.formInline.resetFields(); //清空表单
                  this.$parent.getFounderList();
                } else {
                  this.$message({
            duration: 5000,
                    message: res.message,
                    type: "warning",
                  });
                }
              }
            );
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("/updateHotelRoomDevice", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
            duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
</style>